<template>
	<div class="main_calendar_settings">
		<div class="form__container">
			<CreateCalendarPage :isForm="true" :calendarSettings="calendarSettings" :action="saveSettings"/>
		</div>
	</div>
</template>

<script>
// import CMS from '../../../service/cms/service';
export default {
	name: 'MainSettings',
	components: {
        CreateCalendarPage: () => import('../../../pages/CreateCalendar.vue')
    },
	
	computed: {
		calendarSettings(){
			return this.$store.getters.calendarMainSettings
		}
	},
	methods: {
		saveSettings(){

		}
	},
};
</script>